const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
]

export function prettyDate(input) {
  const discussed = new Date(input)
  return `${monthNames[discussed.getMonth()]} ${discussed.getDate()}, ${discussed.getFullYear()}` 
}


