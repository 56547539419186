import React from 'react'
import { Link } from 'gatsby'
import { prettyDate } from '../../utils/dateConversion'

import Ben from '../../images/ben.jpg'
import Ryan from '../../images/ryan.jpg'
import Brian from '../../images/brian.jpg'
import Eric from '../../images/eric.jpg'

const KNIGHTS = { Ben, Ryan, Brian, Eric }

export default function MeetingBlock({discussionDate, bookChooser}) {
  return (
    <div className={`
      flex flex-col justify-center items-center border-b-2 border-b-red-rooster pb-4
      md:justify-start md:items-end md:flex-row
    `}>
      <div className="relative mr-4">
        <Link 
          className="flex items-center"
          to={`/${bookChooser.toLowerCase()}`} >
          <h4 
            className="text-red-rooster bg-white text-sm italic py-0 px-2 rounded-xl shadow-md absolute -bottom-1 left-1/2 -translate-x-1/2">
              Chooser
          </h4>
          <img className="rounded-full h-20 mb-0" src={KNIGHTS[bookChooser]} />
        </Link>
      </div>
      <div className="mt-6">
        <h4 className="text-gray-500 italic">Discussed on</h4>
        <p>{prettyDate(discussionDate)}</p>
      </div>
    </div>
  )
}

