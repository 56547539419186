import React from 'react'
import { graphql } from 'gatsby'
import BookLayout from '../common/BookLayout'
import MeetingBlock from './MeetingBlock'
import { prettyDate } from '../../utils/dateConversion'

const Book = ({ data }) => {
  const {
    author,
    bookChooser,
    discussionDate,
    publishedDate,
    thumbnail,
    title,
  } = data.markdownRemark.frontmatter

  return (
    <BookLayout>
      <section className={`
        flex flex-col items-center justify-center mt-0 
        md:mt-8 lg:flex-row lg:items-start
      `}>
        <div className="bg-gray-100 w-full md:px-8 md:basis-96 md:grow-0 md:shrink-0">
          <div className="flex flex-col items-center py-8 md:items-start">
            <img src={thumbnail} />
            <h2 className="text-2xl">{title}</h2>
            <p className="italic">{author}</p>
            <p className="mb-2">{prettyDate(publishedDate)}</p>
          </div>
        </div>
        <div className="py-4 px-4">
          <MeetingBlock 
            bookChooser={bookChooser}
            discussionDate={discussionDate} />
          <div className="py-4">
            <div className="book-remark" dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
          </div>
        </div>
      </section>
    </BookLayout>
  )
}

export const query = graphql`
query($slug: String!) {
  markdownRemark(fields: { slug: { eq: $slug } }) {
    frontmatter {
      title
      author
      publishedDate
      thumbnail
      bookChooser
      discussionDate
    }
    html
  }
}
`

export default Book
